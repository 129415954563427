import { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";

const Layout = () => {
    const [userData, setUserData] = useState({ loggedIn: false });
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/api/user/check-session', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setUserData(data);
        })
    }, []);

    const handleLogout = () => {
        fetch('/api/user/logout', {
            method: 'POST',
            credentials: 'include'
        })
        .then(() => {
            setUserData({ loggedIn: false });
            navigate('/');
            window.location.reload();
        })
        .catch(error => {
            console.error(`Beim Ausloggen ist ein Fehler aufgetreten: ${error}`);
        })
    }

    return (
    <>
        <nav className="bg-gray-800 p-4">
            <div className="w-full flex items-center">
                <div className="flex space-x-4">
                    <Link to="/">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Übersicht
                        </button>
                    </Link>
                    <Link to="/ticket">
                        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                            Tickets
                        </button>
                    </Link>
                    <Link to="/admin">
                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Adminpanel
                        </button>
                    </Link>
                </div>
                <div className="ml-auto">
                    { !userData.loggedIn ? (
                        <Link to="/login">
                            <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                                Login
                            </button>
                        </Link>
                    ) : (
                        <div className="flex flex-row align-text-bottom">
                            <span style={{ color: "white" }}>Hallo, {userData.username}</span>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </nav>

        <Outlet />
    </>
    );
};
export default Layout;