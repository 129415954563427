import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BktLogo from '../assets/bktLogo-AKTUELL.png'

const Login = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();
        
        setLoading(true);

        const form = e.target;
        const formData = new FormData(form);
        
        fetch(
            '/api/benutzer/login',
            {
                method: 'POST',
                headers: {
                    'username': formData.get('username'),
                    'password': formData.get('password'),
                }
            }
        )
        .then(
            async response => {
                if (!response.ok) {
                    await response.text().then((text) => {
                        setErrorMessage(`Fehler: ${text || response.statusText}`);
                    });

                    return;
                }

                // Login erfolgreich
                navigate('/');
            }
        )
        .catch((error) => {
            console.log(error);
            setErrorMessage(error.message);
        })
        .finally(() => {
            setLoading(false);
        });
    }
    
    return(
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div className="flex flex-col relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10 space-y-4">
                <img src={ BktLogo } alt="BKT-Logo" className="mx-auto mb-4" />
                {errorMessage && (
                    <div className="text-red-500 mb-4 text-center">{errorMessage}</div>
                )}
                <form method='post' onSubmit={handleSubmit} className='flex flex-col'>
                    <label htmlFor="input-username">Benutzername :</label>
                    <input name="username" id="input-username" className="border rounded p-2" />
                    <label htmlFor="input-pwd">Passwort :</label>
                    <input type="password" name="password" id="input-pwd" className="border rounded p-2" />
                    <button type='submit' name='submit' disabled={loading} className='border-2 text-lg mt-2 mb-2 rounded-lg w-1/2 mx-auto h-11'>
                        {loading ? 'Lädt...' : 'Anmelden'}
                    </button>
                </form>             
            </div>
        </div>
    );
};  
export default Login;