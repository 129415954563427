import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Uebersicht = () => {
    const [weekData, setweekdata] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/api/kalender/week', {
            method: 'GET',
            headers: {
                timestamp: new Date().getTime()
            }
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            data = data.map(element => ({
                ...element,
                datum: new Date(element.datum)
            }));
            setweekdata(data);
        })
    }, []);

    function getKW(date) {
        const yearStart = new Date(Date.UTC(date.getFullYear(), 0, 1));
        const dayDifference = Math.floor((date - yearStart) / (1000 * 60 * 60 * 24));
        const week = Math.ceil((dayDifference + yearStart.getDay()) / 7);
        return week;
    }   
    
    function nextWeek() {
        const datum = new Date(weekData[0].datum);
        datum.setDate(datum.getDate() + 7);

        fetch('/api/kalender/week', {
            method: 'GET',
            headers: {
                timestamp: datum.getTime()
            }
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            data = data.map(element => ({
                ...element,
                datum: new Date(element.datum)
            }));
            setweekdata(data);
        })
    }

    function prevWeek() {
        const datum = new Date(weekData[0].datum);
        datum.setDate(datum.getDate() - 7);

        fetch('/api/kalender/week', {
            method: 'GET',
            headers: {
                timestamp: datum.getTime()
            }
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            data = data.map(element => ({
                ...element,
                datum: new Date(element.datum)
            }));
            setweekdata(data);
        })
        console.log(datum);
    }
   
    function openStunde() {
        navigate("/Stunde");
    }

    return(
        <table style={{width: "100vw", height: "47vw"}} className="tableklasse">
            <tr>
                <th style={{width:"4%",height:"1%"}} className="border-4 border-white">
                    <div className="flex flex-col">
                        KW {weekData.length === 0 ? (
                        '...' ) : (
                            getKW(weekData[0].datum)
                        )}
                        <div>
                            <button style={{marginRight: "5px"}} className="border-2 border-black" onClick={prevWeek}>&#171;</button>
                            <button className="border-2 border-black" onClick={nextWeek}>&#187;</button>
                        </div>
                    </div>
                </th>
                <th className="Wochentagheader">
                    { weekData.length === 0 ? (
                        "Error 187"
                     ) : (
                        weekData[0].datum.toLocaleDateString('de-DE')
                    )}
                </th>
                <th className="Wochentagheader">
                    { weekData.length === 0 ? (
                        "Error 187"
                     ) : (
                        weekData[1].datum.toLocaleDateString('de-DE')
                    )}
                </th>
                <th className="Wochentagheader">
                { weekData.length === 0 ? (
                        "Error 187"
                     ) : (
                        weekData[2].datum.toLocaleDateString('de-DE')
                    )}
                </th>
                <th className="Wochentagheader">
                { weekData.length === 0 ? (
                        "Error 187"
                     ) : (
                        weekData[3].datum.toLocaleDateString('de-DE')
                    )}
                </th>
                <th className="Wochentagheader">
                { weekData.length === 0 ? (
                        "Error 187"
                     ) : (
                        weekData[4].datum.toLocaleDateString('de-DE')
                    )}
                </th>
                <th className="Wochentagheader">
                { weekData.length === 0 ? (
                        "Error 187"
                     ) : (
                        weekData[5].datum.toLocaleDateString('de-DE')
                    )}
                </th>
            </tr>
            <tr className="Heightspalten">
                <td>
                        7:30-8.15
                </td>
                <td onClick={openStunde}>
                    Stunde 1          
                </td>
                <td>
                    Stunde 1
                </td>
                <td>
                    Stunde 1
                </td>
                <td>
                    Stunde 1
                </td>
                <td>
                    Stunde 1
                </td>
                <td>
                    Stunde 1
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    8:15-9:00
                </td>
                <td>
                    Stunde 2
                </td>
                <td>
                    Stunde 2
                </td>
                <td>
                    Stunde 2
                </td>
                <td>
                    Stunde 2
                </td>
                <td>
                    Stunde 2
                </td>
                <td>
                    Stunde 2
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    9:15-10:00
                </td>
                <td>
                    Stunde 3
                </td>
                <td>
                    Stunde 3
                </td>
                <td>
                    Stunde 3
                </td>
                <td>
                    Stunde 3
                </td>
                <td>
                    Stunde 3
                </td>
                <td>
                    Stunde 3
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    10:00-10:45
                </td>
                <td>
                    Stunde 4
                </td>
                <td>
                    Stunde 4
                </td>
                <td>
                    Stunde 4
                </td>
                <td>
                    Stunde 4
                </td>
                <td>
                    Stunde 4
                </td>
                <td>
                    Stunde 4
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    11:00-11.45
                </td>
                <td>
                    Stunde 5
                </td>
                <td>
                    Stunde 5
                </td>
                <td>
                    Stunde 5
                </td>
                <td>
                    Stunde 5
                </td>
                <td>
                    Stunde 5
                </td>
                <td>
                    Stunde 5
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    11:45-12:30
                </td>
                <td>
                    Stunde 6
                </td>
                <td>
                    Stunde 6
                </td>
                <td>
                    Stunde 6
                </td>
                <td>
                    Stunde 6
                </td>
                <td>
                    Stunde 6
                </td>
                <td>
                    Stunde 6
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    12.45-13.30
                </td>
                <td>
                    Stunde 7
                </td>
                <td>
                    Stunde 7
                </td>
                <td>
                    Stunde 7
                </td>
                <td>
                    Stunde 7
                </td>
                <td>
                    Stunde 7
                </td>
                <td>
                    Stunde 7
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    13.30-14.15
                </td>
                <td>
                    Stunde 8
                </td>
                <td>
                    Stunde 8
                </td>
                <td>
                    Stunde 8
                </td>
                <td>
                    Stunde 8
                </td>
                <td>
                    Stunde 8
                </td>
                <td>
                    Stunde 8
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    14.30-15.15
                </td>
                <td>
                    Stunde 9
                </td>
                <td>
                    Stunde 9
                </td>
                <td>
                    Stunde 9
                </td>
                <td>
                    Stunde 9
                </td>
                <td>
                    Stunde 9
                </td>
                <td>
                    Stunde 9
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    15:15-16:00
                </td>
                <td>
                    Stunde 10
                </td>
                <td>
                    Stunde 10
                </td>
                <td>
                    Stunde 10
                </td>
                <td>
                    Stunde 10
                </td>
                <td>
                    Stunde 10
                </td>
                <td>
                    Stunde 10
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    16:15-17:00
                </td>
                <td>
                    Stunde 11
                </td>
                <td>
                    Stunde 11
                </td>
                <td>
                    Stunde 11
                </td>
                <td>
                    Stunde 11
                </td>
                <td>
                    Stunde 11
                </td>
                <td>
                    Stunde 11
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    17:00-17:45
                </td>
                <td>
                    Stunde 12
                </td>
                <td>
                    Stunde 12
                </td>
                <td>
                    Stunde 12
                </td>
                <td>
                    Stunde 12
                </td>
                <td>
                    Stunde 12
                </td>
                <td>
                    Stunde 12
                </td>
            </tr>
            <tr className="Heightspalten">
                <td>
                    18:00-18.45
                </td>
                <td>
                    Stunde 13
                </td>
                <td>
                    Stunde 13
                </td>
                <td>
                    Stunde 13
                </td>
                <td>
                    Stunde 13
                </td>
                <td>
                    Stunde 13
                </td>
                <td>
                    Stunde 13
                </td>
            </tr>
            <tr className="Heightspalten">
                <td className="bg-zinc-300 border-white border-r-4">
                    18:45-19.30
                </td>
                <td >
                    Stunde 14
                </td>
                <td >
                    Stunde 14
                </td>
                <td >
                    Stunde 14
                </td>
                <td >
                    Stunde 14
                </td>
                <td >
                    Stunde 14
                </td>
                <td >
                    Stunde 14
                </td>
            </tr>
        </table>
    );
};
export default Uebersicht;