import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import Adminpanel from "./pages/Adminpanel/Adminpanel";
import Uebersicht from "./pages/Stundenplan/Uebersicht";
import Stunde from "./pages/Stundenplan/Stunde";
import Ticketsystem from "./pages/Ticketsystem/Ticketsystem";
import Notfound from "./pages/Notfound";

export default function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Uebersicht />} />
        <Route path="admin/*" element={<Adminpanel />} />
        <Route path="stunde" element={<Stunde />} />
        <Route path="ticket/*" element={<Ticketsystem />} />
        <Route path="*" element={<Notfound />} />
      </Route>
      <Route path="login" element={<Login />} />
    </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);