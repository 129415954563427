import React from "react";
import { NavLink } from "react-router-dom";

const SideNavigation = ({ links }) => {
    return (
        <aside className="top-0 left-0 z-40 w-36 h-screen transition-transform -translate-x-full sm:translate-x-0">
            <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
                <ul>
                    <li>
                        <NavLink
                            id="adminpanel-navigation-benutzer"
                            to="benutzer"
                            className={({ isActive }) =>
                                `flex items-center p-2 text-gray-900 rounded-lg group ${isActive ? 'bg-gray-200' : 'hover:bg-gray-100'}`
                            }
                        >
                            Benutzer
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            id="adminpanel-navigation-raeume"
                            to="raeume"
                            className={({ isActive }) =>
                                `flex items-center p-2 text-gray-900 rounded-lg group ${isActive ? 'bg-gray-200' : 'hover:bg-gray-100'}`
                            }
                        >
                            Räume
                        </NavLink>
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default SideNavigation;
