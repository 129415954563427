import { useState, useEffect } from "react";

const Stunde = () => {
    const [raeumeList, setRaeumeList] = useState([]);
    const [filteredRaeumeList, setFilteredRaeumeList] = useState([]);

    useEffect(() => {
        fetch('/api/raeume/', {
            method: 'GET',
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setRaeumeList(data);
            setFilteredRaeumeList(data);
        })
    }, []);

    function filterRaeume(){
        var filteredRaeume = raeumeList.filter(function (el){
            return el.seats <= document.getElementById("filter-sitzplaetze").value
        });

        setFilteredRaeumeList(filteredRaeume);
    }
    
    return (
        <>
            <div style={{display:"flex", flexDirection:"row", marginTop:"5px", marginLeft:"5px"}}>
                <div>
                        {
                            filteredRaeumeList.map(raum =>
                                <>
                                    <button className="border rounded shadow p-2 m-0.5 w-full hover:bg-zinc-200 active:bg-zinc-400 focus:outline-none focus:ring focus:ring-zinc-500 focus:bg-zinc-400 font-semibold">Raum: {raum.roomName}</button>
                                    <br />
                                </>
                                
                            )
                        }
                </div>
                <div style={{marginLeft:"6px"}}>
                    <table>
                        <tr>
                            <td>
                                <label>Sitzplaetze</label>
                            </td>
                            <td>
                                <input onChange={() => filterRaeume()} id="filter-sitzplaetze" />
                            </td>  
                        </tr>
                        <tr>
                            <td>
                                <label>Computer</label>
                            </td>
                            <td>
                                <input />
                            </td>  
                        </tr>
                        <tr>
                            <td>
                                <label>Beamer</label>
                            </td>
                            <td>
                                <input type="checkbox" />
                            </td>  
                        </tr>    
                    </table>
                </div>
            </div>
        </>
    );
};
export default Stunde;