import { Routes, Route, Navigate } from "react-router-dom";
import SideNavigation from "../SideNavigationPage";
import Benutzer from "./Benutzer";
import Raeume from "./Raeume";

const Adminpanel = () => {
    const routes = [
        {
            path: '/',
            element: <Navigate to="benutzer" replace />
        },
        {
            path: 'benutzer',
            element: <Benutzer />
        },
        {
            path: 'raeume',
            element: <Raeume />
        }
    ]

    return (
        <>
            <div className="flex">
                <SideNavigation />
                <div className="flex-1 p-4">
                    <Routes>
                        {routes.map((route, routeIndex) => {
                            return <Route key={routeIndex} path={route.path} element={route.element} />
                        })}
                    </Routes>
                </div>
            </div>
        </>
    );
};

export default Adminpanel;
