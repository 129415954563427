import React from "react";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import Ticketuebersicht from "./Ticketuebersicht";
import Ticketarchiv from "./Ticketarchiv";

const Ticketsystem = () => {
    return (
        <div className="flex">
            <aside className="top-0 left-0 z-40 w-36 h-screen transition-transform -translate-x-full sm:translate-x-0">
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
                    <ul>
                        <li>
                            <NavLink
                                id="ticketsystem-navigation-ticketuebersicht"
                                to="ticketuebersicht"
                                className={({ isActive }) =>
                                    `flex items-center p-2 text-gray-900 rounded-lg group ${isActive ? 'bg-gray-200' : 'hover:bg-gray-100'}`
                                }
                            >
                                Übersicht
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                id="ticketsystem-navigation-ticketarchiv"
                                to="ticketarchiv"
                                className={({ isActive }) =>
                                    `flex items-center p-2 text-gray-900 rounded-lg group ${isActive ? 'bg-gray-200' : 'hover:bg-gray-100'}`
                                }
                            >
                                Archiv
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </aside>

            <div className="flex-1 p-4">
                <Routes>
                    <Route path="/" element={<Navigate to="ticketuebersicht" replace />} />
                    <Route path="ticketuebersicht" element={<Ticketuebersicht />} />
                    <Route path="ticketarchiv" element={<Ticketarchiv />} />
                </Routes>
            </div>
        </div>
    );
};

export default Ticketsystem;
