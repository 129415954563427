import { useState, useEffect } from "react";

const STATUS_WAITING_AUTHOR = 0;
const STATUS_WAITING_ADMIN = 1;
const STATUS_CLOSED = 2;

const ticketStates = [
    {
        id: STATUS_CLOSED,
        text: 'Geschlossen'
    },
    {
        id: STATUS_WAITING_AUTHOR,
        text: 'Warte auf Ticketauthor'
    },
    {
        id: STATUS_WAITING_ADMIN,
        text: 'Warte auf Administrator'
    }
]

const dummyTickets = [
    {
        betreff: 'Betreff1', 
        beschreibung: 'beschreibung1',
        authorID: 2,
        status: STATUS_WAITING_ADMIN,
        zeitpunkt: 'zeitpunkt1'
    },
    {
        betreff: 'Betreff2',
        beschreibung: 'beschreibung2',
        authorID: 1,
        status: STATUS_CLOSED,
        zeitpunkt: 'zeitpunkt2'
    },
    {
        betreff: 'Betreff3',
        beschreibung: 'beschreibung3',
        authorID: 11,
        status: STATUS_WAITING_AUTHOR,
        zeitpunkt: 'zeitpunkt3'
    },
    {
        betreff: 'Betreff4',
        beschreibung: 'beschreibung4',
        authorID: 11,
        status: STATUS_CLOSED,
        zeitpunkt: 'zeitpunkt4'
    }
];

const Ticketuebersicht = () => {
    const [userData, setUserData] = useState({ loggedIn: false });
    const [tickets, setTickets] = useState([]);
    
    useEffect(() => {
        fetch('/api/benutzer/check-session', {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setUserData(data);

            if (userData.loggedIn)
                setTickets(dummyTickets.filter(ticket => ticket.authorID === userData.userID));
        })

    }, [userData.loggedIn, userData.userID]);

    return (
        <>
            {tickets.length === 0 ? (
                <span>Keine Tickets geladen</span>
            ) : (
                <div className="flex flex-wrap flex-col">
                    {tickets.map((ticket, ticketIndex) => (
                        <div key={ticketIndex} className= "border shadow m-4 w-xs max-w-xs">
                            <span>{ticket.betreff} {ticket.zeitpunkt} {ticketStates.find(t => t.id === ticket.status).text}</span>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};  
export default Ticketuebersicht;