import { useState, useEffect } from "react";

const Raeume = () => {
    const [raeumeList, setRaeumeList] = useState([]);
    const [filteredRaeume, setFilteredRaeume] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState({
        beamer: null,
        seats: '',
        computers: '',
    });

    useEffect(() => {
        fetch('/api/raeume/', {
            method: 'GET',
        })
        .then(response => response.json())
        .then(data => {
            setRaeumeList(data);
            setFilteredRaeume(data);
        })
        .catch(error => console.error('Error fetching room data:', error));
    }, []);

    useEffect(() => {
        const filtered = raeumeList.filter(raum => {
            return (
                (activeFilters.beamer === null || raum.beamer == activeFilters.beamer) &&
                (activeFilters.seats === '' || raum.seats >= activeFilters.seats) &&
                (activeFilters.computers === '' || raum.computers >= activeFilters.computers)
            );
        });

        setFilteredRaeume(filtered);
    }, [activeFilters, raeumeList]);

    const isFilterApplied = () => {
        return !(
            activeFilters.beamer === null &&
            activeFilters.seats === '' &&
            activeFilters.computers === ''
        );
    };

    const closeFilterDialog = () => {
        setIsFilterOpen(false);
    };

    const showFilterDialog = () => {
        setIsFilterOpen(true);

        setTimeout(() => {
            document.getElementById('beamer-filter').value = activeFilters.beamer == null ? "" : (activeFilters.beamer ? "true" : "false");
            document.getElementById('seats-filter').value = activeFilters.seats;
            document.getElementById('computers-filter').value = activeFilters.computers;
        }, 0);
    };

    const submitFilterDialog = () => {
        const beamerValue = document.getElementById("beamer-filter").value;
        const seatsValue = document.getElementById("seats-filter").value;
        const computersValue = document.getElementById("computers-filter").value;

        setActiveFilters({
            beamer: beamerValue === 'true' ? true : (beamerValue === 'false' ? false : null),
            seats: seatsValue,
            computers: computersValue,
        });

        setIsFilterOpen(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitFilterDialog();
        } else if (e.key === 'Escape') {
            closeFilterDialog();
        }
    };

    const raumAttributes = [
        {
            name: 'beamer',
            display: 'Beamer',
            isBool: true
        },
        {
            name: 'seats',
            display: 'Sitzplätze',
            isBool: false
        },
        {
            name: 'computers',
            display: 'Computer',
            isBool: false
        }
    ]

    return (
        <>
            <div className="flex flex-wrap flex-row mb-4 gap-4">
                <button onClick={showFilterDialog} className="border shadow bg-zinc-50 rounded-lg p-2">Filtern</button>
                <button onClick={() => alert('TODO')} className="border shadow bg-zinc-50 rounded-lg p-2 ">Hinzufügen...</button>
            </div>

            {isFilterApplied() && (
                <div className="text-sm mb-4">
                    <strong>Aktuelle Filter: </strong>
                    {activeFilters.beamer !== null && `Beamer: ${activeFilters.beamer ? 'Ja' : 'Nein'}, `}
                    {activeFilters.seats && `Sitzplätze ≥ ${activeFilters.seats}, `}
                    {activeFilters.computers && `Computer ≥ ${activeFilters.computers}`}
                </div>
            )}

            {isFilterOpen && (
                <div 
                    className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center"
                    onKeyDown={handleKeyDown}
                >
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Filtereinstellungen</h2>
                        <div className="mb-4">
                            <label className="block mb-1">Beamer:</label>
                            <select id="beamer-filter" className="border p-1 rounded w-full">
                                <option value="">Keine Auswahl</option>
                                <option value="true">Ja</option>
                                <option value="false">Nein</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Mindestanzahl Sitzplätze:</label>
                            <input
                                type="text"
                                id="seats-filter"
                                placeholder="Anzahl"
                                className="border p-1 rounded w-full"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Mindestanzahl Computer:</label>
                            <input
                                type="text"
                                id="computers-filter"
                                placeholder="Anzahl"
                                className="border p-1 rounded w-full"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                            />
                        </div>
                        <div className="flex justify-end">
                            <button 
                                onClick={closeFilterDialog}
                                className="bg-gray-300 hover:bg-gray-400 rounded px-4 py-2 mr-2"
                            >
                                Abbrechen
                            </button>
                            <button 
                                onClick={submitFilterDialog}
                                className="bg-blue-500 hover:bg-blue-600 text-white rounded px-4 py-2"
                            >
                                Anwenden
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex flex-wrap gap-4">
                {filteredRaeume.map((raum, raumIndex) => (
                    <div key={raumIndex} className="border rounded-lg p-6 w-full max-w-xs bg-white shadow-md">
                        <h3 className="text-xl font-semibold mb-2">
                            {raum.roomName}
                        </h3>
                        <div className="flex flex-wrap gap-1">
                            {raumAttributes.map((attr, attrIndex) => (
                                <span 
                                    key={attrIndex} 
                                    className="border rounded-full px-2 py-1 text-xs font-semibold"
                                >
                                    {attr.display}: {attr.isBool ? (raum[attr.name] ? 'Ja' : 'Nein') : raum[attr.name]}
                                </span>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Raeume;
