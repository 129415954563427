import { useState, useEffect } from "react";

const Stunde = () => {
    const [roomList, setRoomList] = useState([]);
    const [filteredRaeumeList, setFilteredRaeumeList] = useState([]);

    useEffect(() => {
        fetch('/api/rooms/', {
            method: 'GET',
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setRoomList(data);
            setFilteredRaeumeList(data);
        })
    }, []);

    function filterRaeume(){
        var filteredRaeume = roomList.filter(function (el){
            if(document.getElementById("filter-sitzplaetze").value === ""){
                return roomList
            } else {
                return el.seats <= document.getElementById("filter-sitzplaetze").value
            }
        }).filter(function (el){
            if(document.getElementById("filter-computer").value === ""){
                return roomList
            } else {
                return el.seats <= document.getElementById("filter-computer").value
            }
        }).filter(function (el){
            if(document.getElementById("beamer-check").checked === true){
                return roomList
            } else {
                return el.seats <= document.getElementById("beamer-check").checked
            }
        });

        setFilteredRaeumeList(filteredRaeume);
    }

    return (
        <>
            <div style={{display:"flex", flexDirection:"row", marginTop:"5px", marginLeft:"5px"}}>
                <div>
                        {
                            filteredRaeumeList.map(room =>
                                <>
                                    <button className="border rounded shadow p-2 m-0.5 w-full hover:bg-zinc-200 active:bg-zinc-400 focus:outline-none focus:ring focus:ring-zinc-500 focus:bg-zinc-400 font-semibold">Raum: {room.roomName}</button>
                                    <br />
                                </>

                            )
                        }
                </div>
                <div style={{marginLeft:"6px"}}>
                    <table>
                        <tr>
                            <td>
                                <label>Sitzplaetze</label>
                            </td>
                            <td>
                                <input onChange={() => filterRaeume()} id="filter-sitzplaetze" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Computer</label>
                            </td>
                            <td>
                                <input onChange={() => filterRaeume()} id="filter-computer" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Beamer</label>
                            </td>
                            <td>
                                <input type="checkbox" id="beamer-check"/>
                            </td>  
                        </tr>    
                    </table>
                </div>
            </div>
        </>
    );
};
export default Stunde;
